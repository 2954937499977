<template>
  <div>
    <span class="block w-full text-center">{{ size | capitalize}}</span>
    <div v-if="banner[`${size}_${locale}`]">
      <div class="image-container w-64 h-24 mx-auto flex items-center justify-center">
        <img :src="banner[`${size}_${locale}`]" alt="image" class="responsive mb-2">
      </div>
      <div v-if="$acl.check('admin')" class="modify-image flex justify-around">
        <input type="file" class="hidden" :ref="`update_${size}_${locale}`" @change="updateCurrImg($event, `${size}_${locale}`)" accept="image/*">
        <vs-button class="mr-4" type="flat" @click="$refs[`update_${size}_${locale}`].click()" icon-pack="feather" :title="$t('change')" icon="icon-repeat"></vs-button>
        <vs-button type="flat" color="#999" @click="removeCurrImg(`${size}_${locale}`)" icon-pack="feather" :title="$t('remove')" icon="icon-trash"></vs-button>
      </div>
    </div>
    <div v-else-if="$acl.check('admin')" class="">
      <div class="vx-col upload-image flex mt-4">
        <input type="file" class="hidden" :ref="`upload_${size}_${locale}`" @change="updateCurrImg($event, `${size}_${locale}`)" accept="image/*">
        <vs-button @click="$refs[`upload_${size}_${locale}`].click()" type="border" icon-pack="feather" icon="icon-upload" class="m-auto">{{$t('upload')}}</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      reqiured: true
    },
    banner: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateCurrImg (input, field) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.banner[field] = e.target.result
          this.$http.post(`/baners/upload-image/${this.banner.uuid}`, {
            photo: this.banner[field],
            key: field
          }).then(() => {
            // console.log(response)
            this.$vs.notify({
              title: 'Success',
              text: 'Banner image successfully uploaded!',
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'primary'
            })
          }).catch(err => {
            this.$vs.notify({
              title: 'Error',
              text: err.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
            this.banner[field] = null
          })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    async removeCurrImg (field) {
      // console.log(field)
      await this.$http.delete(`/baners/delete-image/${this.banner.uuid}`, {
        data: { key: field }
      }).then(() => {
        // console.log(response)
        this.$vs.notify({
          title: 'Success',
          text: 'Banner image successfully deleted!',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
        this.banner[field] = null
      }).catch(err => {
        this.$vs.notify({
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.image-container {
  img {
    margin: 15px 0 15px;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
</style>
